;(function () {
  const elements = document.querySelectorAll('[data-icon]') as NodeListOf<HTMLElement>

  if (!elements.length) {
    return
  }

  elements.forEach(async element => {
    const { icon } = element.dataset
    if (!icon) {
      return
    }

    const img = await (async () => {
      if (icon.match(/\.svg$/)) {
        const svgWrapper = document.createElement('div')
        const markup = await fetch(icon).then(res => res.text())
        svgWrapper.innerHTML = markup || ''

        return svgWrapper.querySelector('svg')!
      } else {
        const img = document.createElement('img')
        img.src = icon

        return img
      }
    })()

    img.classList.add('stratus-theme-icon')
    img.onerror = function () {
      this.style.setProperty('display', 'none')
    }

    element.prepend(img)
  })
})()
